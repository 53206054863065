import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HomeHero from "../components/homeHero";
import { motion } from "framer-motion";


function ContactPage({ data, location }) {
	let contactPage = data.contactContent.edges[0].node.frontmatter;

	//let contactBody = data.contactContent.edges[0].node.html;
	//console.log(location);
	return (
		<Layout>
			<SEO
				keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
				title="Contact"
			/>
			<HomeHero
				headline={contactPage.contactBannerHeadline}
				copy={contactPage.contactBannerHeadText}
				phone={contactPage.contactBannerPhone}
				image={contactPage.contactBannerImage.childImageSharp.fluid}
			/>
			<section className="bg-gray-300">
			<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.2,
									},
								},
							}}
						>
				<form
					name="contact"
					method="POST"
					action="/thanks/"
					data-netlify-honeypot="bot-field"
					className="mx-auto md:w-1/2 py-20 px-4 sm:px-6"
					data-netlify="true"
				>
					<div hidden>
						<label>
							Don’t fill this out: <input name="bot-field" type="hidden" />
						</label>
					</div>
					<input type="hidden" name="form-name" value="contact" />

					<label
						className="block mb-2 text-xs font-bold uppercase"
						htmlFor="first-name"
					>
						First Name
					</label>

					<input
						className="w-full mb-6 form-input"
						id="first-name"
						name="first-name"
						placeholder="First Name"
						type="text"
						required
					/>

					<label
						className="block mb-2 text-xs font-bold uppercase"
						htmlFor="last-name"
					>
						Last Name
					</label>

					<input
						className="w-full mb-6 form-input"
						id="last-name"
						name="last-name"
						placeholder="Last Name"
						type="text"
					/>
					<label
						className="block mb-2 text-xs font-bold uppercase"
						htmlFor="phone-number"
					>
						Phone Number
					</label>

					<input
						className="w-full mb-6 form-input"
						id="phone-number"
						name="phone-number"
						placeholder="Phone Number"
						type="tel"
						required
					/>
					<label
						className="block mb-2 text-xs font-bold uppercase"
						htmlFor="email-address"
					>
						Email Address
					</label>

					<input
						className="w-full mb-6 form-input"
						id="email-address"
						name="email-address"
						placeholder="Email Address"
						type="email"
						required
					/>

					<label
						className="block mb-2 text-xs font-bold uppercase"
						htmlFor="message"
					>
						Message
					</label>

					<textarea
						className="w-full mb-6 form-textarea"
						id="message"
						name="message"
						placeholder="Say something..."
						rows="8"
						required
					/>

					<button type="submit" className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600">
						Submit
					</button>
				</form>
				</motion.div>
			</section>

			<div className="map-wrapper">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.2748944017153!2d-88.94952758405825!3d42.69789977916552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88061aaecf822b1f%3A0x9447a1d114df6045!2sL%20P%20Tree%20Services%20Inc!5e0!3m2!1sen!2sus!4v1604761372724!5m2!1sen!2sus"
					width={600}
					height={450}
					frameborder={0}
					style={{ border: 0 }}
					allowfullscreen
					aria-hidden={false}
					title="LP Tree Service Map"
				></iframe>
			</div>
		</Layout>
	);
}

export default ContactPage;

export const contactQuery = graphql`
	query {
		contactContent: allMarkdownRemark(
			filter: { frontmatter: { pageName: { eq: "contact" } } }
		) {
			edges {
				node {
					frontmatter {
						contactBannerHeadline
						contactBannerHeadText
						contactBannerPhone
						contactBannerImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
					}
					rawMarkdownBody
					id
					html
				}
			}
		}
	}
`;
